import {
  Box,
  ChakraBaseProvider,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Home from "./screens/Home";
import { extendTheme } from "@chakra-ui/react";
import { AuthProvider } from "./auth/AuthProvider"; // Import your AuthProvider
import Login from "./screens/Login";
import Fonts from "./Fonts";
import SideNav from "./components/navbar/SideNav";
import MobileNav from "./components/navbar/MobileNav";
import { useState } from "react";
import EventsList from "./screens/EventList";
import EventInfo from "./screens/EventInfo";
import CheckpointsList from "./screens/CheckpointList";
import QuestionList from "./screens/QuestionList";
import { useGlobalState } from "./share/share_states";
import SpinnerModal from "./modals/SpinnerModal";
import Report from "./screens/Report";
import Setting from "./screens/Setting";
import Dashboard from "./screens/Dashboard";

const theme = extendTheme({
  fonts: {
    heading: "Noto",
    body: "Noto",
  },
});

function App() {
  const [loading] = useGlobalState("loading");
  const [hideComponents, setHideComponents] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const hideTool = (hide) => {
    setHideComponents(hide);
  };

  return (
    <div className="App">
      <ChakraBaseProvider theme={theme}>
        <AuthProvider>
          
          <Fonts />
          <Router>
            <Flex>
              <SpinnerModal isOpen={loading} />
              {!hideComponents && <SideNav isOpen={isOpen} onOpen={onOpen} onClose={onClose} />}
              <Box width="full">
                {!hideComponents && <MobileNav hideTool={hideTool} onOpen={onOpen} />}
                <Box ml={{ base: 0, md: hideComponents ? 0 : 240 }}>
                  <main>
                    <Routes>
                      <Route path="/" element={<EventsList />} />
                      <Route path="/home" element={<EventsList />} />
                      <Route path="/event" element={<EventInfo />} />
                      <Route path="/question" element={<QuestionList />} />
                      <Route path="/checkpoint" element={<CheckpointsList />} />
                      <Route path="/report" element={<Report/>} />
                      <Route path="/dashboard" element={<Dashboard/>} />
                      <Route path="/setting" element={<Setting/>} />
                      <Route path="/login" element={<Login hideTool={hideTool} />} />
                      
                      {/* Redirect any other route to "/home" */}
                      <Route path="*" element={<Navigate to="/login" />} />
                    </Routes>
                  </main>
                </Box>
              </Box>
            </Flex>
          </Router>
          
        </AuthProvider>
      </ChakraBaseProvider>
    </div>
  );
}

export default App;
