import {
  Avatar,
  Box,
  Circle,
  Flex,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  FiBell,
  FiChevronDown,
  FiMenu,
  FiPauseCircle,
  FiUser,
} from "react-icons/fi";
import { useAuth } from "../../auth/AuthProvider";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const MobileNav = ({ hideTool, onOpen, ...rest }) => {
  const { user, checkUser, login, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (checkUser() == null) {
      hideTool(true);
      navigate("/login");
    }
  }, []);

  const signout = () => {
    hideTool(true);
    logout();
    navigate("/login");
  };

  return (
    /*<Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("gray.300", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "space-between" }}
      {...rest}
  >*/
  <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("gray.300", "gray.900")}
      //bgImage="url('/images/header.png')"  // Set your image path here
      bgSize="cover"
      bgPosition="left"
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "space-between" }}
      {...rest}
    >
      {/*<Text ml={220} fontSize="2xl" color={"gray.500"}>Event Management</Text>*/}
      <Text></Text>

      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text
        display={{ base: "flex", md: "none" }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold"
      >
        Logo
      </Text>

      <HStack spacing={{ base: "0", md: "6" }}>
        <IconButton
          size="lg"
          variant="ghost"
          aria-label="open menu"
          icon={<FiBell />}
        />
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Circle
                  size="40px"
                  bg="teal.500"
                  color="white"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon as={FiUser} boxSize={6} />
                </Circle>
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="xs" color="gray.600">
                    Admin
                  </Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
            >
              {/*<MenuItem>Profile </MenuItem>
              <MenuItem>Settings</MenuItem>
              <MenuDivider />*/}
              <MenuItem
                onClick={() => {
                  signout();
                }}
              >
                Sign out
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

export default MobileNav;
