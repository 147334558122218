import { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // You can initialize with a user object if the user is already authenticated.

  const checkUser = () => {
    const access_token = localStorage.getItem('access_token');
    const refresh_token = localStorage.getItem('refresh_token');
    const user_data = localStorage.getItem('user');

    if(user_data){
        setUser(JSON.parse(user_data))
        return JSON.parse(user_data);
    }
    else{
        return null;
    }
  }

  const login = (userData) => {
    console.log("userData: "+userData)
    localStorage.setItem('user', JSON.stringify(userData));
    setUser(userData);
  };

  const logout = () => {
    console.log("logout...")
    localStorage.setItem("user", null)
    localStorage.setItem("access_token", null)
    localStorage.setItem("refresh_token", null)
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, checkUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);