import { Box, CloseButton, Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import NavItem from "./NavItem";
import { FiCompass, FiHome, FiPieChart, FiSettings, FiStar, FiTrendingUp, FiFile } from "react-icons/fi";

const SidebarContent = ({ onClose, setMenu, selectedMenu, ...rest }) => {
    const LinkItems = [
        { name: 'Home', value: "home", icon: FiHome },
        { name: 'Report', value: "report", icon: FiFile},
        { name: 'Dashboard', value: "dashboard", icon: FiPieChart },
        //{ name: 'Settings', value: "setting", icon: FiSettings },
      ]

    return (
      <Box
        transition="3s ease"
        bg={useColorModeValue('gray.500', 'gray.900')}
        borderRight="1px"
        borderRightColor={useColorModeValue('gray.200', 'gray.700')}
        w={{ base: 'full', md: 60 }}
        pos="fixed"
        h="full"
        {...rest}>
        <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
          <Box>
            <Image w="150px" src="/images/txid_logo.png" />
          </Box>
          <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
        </Flex>
        {LinkItems.map((link) => (
          <NavItem
            color={"black"}
            key={link.name}
            icon={link.icon}
            isSelected={selectedMenu === link.value}
            onClick={() => {
              setMenu(link.value);
            }}>
            <Text color={selectedMenu === link.value ? 'black' : 'white'}>{link.name}</Text>
          </NavItem>
        ))}
      </Box>
    );
  };

  export default SidebarContent;