
'use client'

import {
  Drawer,
  DrawerContent,
} from '@chakra-ui/react'
import { useState } from 'react'
import SidebarContent from './SidebarContent'
import { useNavigate } from 'react-router-dom'
  
  const SideNav = ({isOpen, onOpen, onClose}) => {
    const navigate = useNavigate()
    const [selectedMenu, setSelectedMenu] = useState('home');
  
    const setMenu = (menu) => {
      setSelectedMenu(menu);
      navigate(`/${menu}`)
    };
  
    return (
        <>
        <SidebarContent
          onClose={() => onClose}
          setMenu={setMenu}
          selectedMenu={selectedMenu}
          display={{ base: 'none', md: 'block' }}
        />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent setMenu={setMenu} onClose={onClose} selectedMenu={selectedMenu} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
      </>
    );
  };



export default SideNav