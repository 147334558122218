import { Box, Flex, Spinner, Text } from "@chakra-ui/react";

const TitleNumber = ({ title, value, widthInPercent, ...rest }) => {
  return (
    <Flex
      {...rest}
      width={`${widthInPercent ?? 33}%`}
      direction={"column"}
      border={"1px solid #e2e8f0"}
      borderRadius={"md"}          
    >
      <Flex borderTopRadius={"md"}  bg={"gray.200"} p={2} align={"center"} justify={"center"} borderBottom={"1px solid #e2e8f0"}>
        <Text fontSize={16} fontWeight={"bold"}>
          {title}
        </Text>
      </Flex>
      <Flex p={2} align={"center"} justify={"center"}>
        {value != undefined?<Text>{value}</Text>:<Spinner/>}
      </Flex>
    </Flex>
  );
};

export default TitleNumber;
