import React from 'react';
import { Modal, ModalOverlay, ModalContent, Spinner, Center, Flex, Text } from '@chakra-ui/react';
import { useGlobalState } from '../share/share_states';

const SpinnerModal = ({ isOpen }) => {
  const [loadingText] = useGlobalState("loadingtext");
  return (
    <Modal isOpen={isOpen} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent h={200} bgColor={"blue.100"}>
        <Flex align={"center"} justify={"center"} w="full" h="full">
          <Spinner size={"lg"} mr={2}/><Text fontSize={22}>{loadingText}</Text>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default SpinnerModal;