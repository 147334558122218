import React from "react";
//import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client"; // Import createRoot from react-dom
import App from "./App";
import './index.css';

/*function App() {
  return (
    <div className="App">
      <Annotataor />
    </div>
  );
}*/

const rootElement = document.getElementById("root");
createRoot(rootElement).render(<App />);
