import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  IconButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  Link,
  Textarea,
} from "@chakra-ui/react";
import {
  DeleteIcon,
  EditIcon,
  ArrowUpIcon,
  ArrowDownIcon,
} from "@chakra-ui/icons";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import moment from "moment";
import { Link as ReactLink } from "react-router-dom";
import { server } from "../util/connect";
import "../css/pagination.css";
import { useNavigate } from 'react-router-dom';
import { setGlobalState } from "../share/share_states";

const EventsList = ({ BreadCrumb, onEventSelected }) => {
  const navigate = useNavigate()
  const toast = useToast();
  const [events, setEvents] = useState([]);
  const [totalEvents, setTotalEvents] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isAddingEvent, setIsAddingEvent] = useState(false);
  const [isEditingEvent, setIsEditingEvent] = useState(false);
  const [editedEvent, setEditedEvent] = useState({
    id: null,
    name: "",
    codeLength: "",
    startDate: "",
    endDate: "",
    status: "inactive",
    description: "",
    venue: "",
    address: "",
  });

  const itemsPerPage = 10; // Number of events per page

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  function formatDate(dateString) {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Intl.DateTimeFormat("th-TH", options).format(
      new Date(dateString)
    );
  }

  function formatSystemDate(dateString) {
    // Assuming dateString is in a format recognized by Moment.js, otherwise parse it accordingly
    const dateMoment = moment(dateString);
    return dateMoment.format("YYYY-MM-DD");
  }

  const addEvent = () => {
    setIsAddingEvent(true);
  };

  const editEvent = (event) => {
    setEditedEvent({
      id: event.id,
      name: event.event_name,
      codeLength: event.code_length,
      startDate: formatSystemDate(event.start_date),
      endDate: formatSystemDate(event.end_date),
      status: event.status,
      description: event.description,
      venue: event.venue,
      address: event.address,
    });
    setIsEditingEvent(true);
  };

  const closeModal = () => {
    setIsAddingEvent(false);
    setIsEditingEvent(false);
    setEditedEvent({
      id: null,
      name: "",
      startDate: "",
      codeLength: "",
      endDate: "",
      status: "Inactive",
      description: "",
      venue: "",
      address: "",
    });
  };

  const getStatusColor = (status) => {
    return (
      <Text color={status == "active" ? "green" : "orange.500"}>{status}</Text>
    );
  };

  const saveEvent = async () => {
    if (
      !editedEvent.name ||
      !editedEvent.codeLength ||
      !editedEvent.startDate ||
      !editedEvent.endDate ||
      !editedEvent.status
    ) {
      toast({
        title: "Validation Error!",
        description: "Please fill in all fields.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    try {
      const formattedStartDate = formatSystemDate(editedEvent.startDate);
      const formattedEndDate = formatSystemDate(editedEvent.endDate);
      setGlobalState("loadingtext", "fetching data, please wait...");
      setGlobalState("loading", true);

      if (editedEvent.id) {
        // Update existing event
        await axios.post(server + `/event/update_event`, {
          id: editedEvent.id,
          event_name: editedEvent.name,
          code_length: editedEvent.codeLength,
          start_date: formattedStartDate,
          end_date: formattedEndDate,
          status: editedEvent.status,
          description: editedEvent.description,
          venue: editedEvent.venue,
          address: editedEvent.address,
        });

        setGlobalState("loading", false);

        Swal.fire({
          icon: "success",
          title: "Event Updated",
          text: "Your event has been updated successfully.",
        });
      } else {
        // Insert new event
        await axios.post(server + "/event/insert_new_event", {
          event_name: editedEvent.name,
          start_date: formattedStartDate,
          code_length: editedEvent.codeLength,
          end_date: formattedEndDate,
          status: editedEvent.status,
          description: editedEvent.description,
          venue: editedEvent.venue,
          address: editedEvent.address,
        });
        setGlobalState("loading", false);

        Swal.fire({
          icon: "success",
          title: "Event Added",
          text: "Your event has been added successfully.",
        });
      }

      fetchData();
      closeModal();
    } catch (error) {
      setGlobalState("loading", false);
      closeModal();
      console.error("Error saving event:", error);

      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to save the event. Please try again later.",
      });
    }
  };

  const deleteEvent = (event) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this event!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios.post(server + `/event/delete_event`, { id: event.id });
        fetchData();

        toast({
          title: "Deleted!",
          description: "Your event has been deleted.",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
    });
  };

  const fetchData = async () => {
    try {
      setGlobalState("loadingtext", "Loading, please wait...");
      setGlobalState("loading", true);
      
      const offset = currentPage * itemsPerPage;
      const response = await axios.post(server + `/event/retrieve_events`, {
        offset,
        limit: itemsPerPage,
      });
      setEvents(response.data.events);
      setTotalEvents(response.data.total);
      setGlobalState("loading", false);
    } catch (error) {
      setGlobalState("loading", false);
      console.error("Error fetching events:", error);
      Swal.fire(`Error!`, error, "error");
    }
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  return (
    <>
    {BreadCrumb? BreadCrumb: <Text></Text>}
    <Box p="4">
      <Button colorScheme="teal" onClick={addEvent}>
        Add Event
      </Button>
      <Table variant="striped" size="md">
        <Thead>
          <Tr>
            <Th>ชื่องาน</Th>
            <Th>วันเริ่มงาน</Th>
            <Th>วันสิ้นสุด</Th>
            <Th>ความยาว code</Th>
            <Th>สถานะ</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {events.map((event, index) => (
            <Tr key={index}>
              <Td>
                <Link
                  color="blue.600"
                  as={ReactLink}
                  to={`/event?eid=${event.id}`}
                >
                  {event.event_name}
                </Link>
              </Td>
              <Td>{formatDate(event.start_date)}</Td>
              <Td>{formatDate(event.end_date)}</Td>
              <Td>{event.code_length}</Td>
              <Td>{getStatusColor(event.status)}</Td>
              <Td>
                <IconButton
                  icon={<EditIcon />}
                  size="sm"
                  colorScheme="teal"
                  marginRight="2"
                  onClick={() => editEvent(event)}
                />
                <IconButton
                  icon={<DeleteIcon />}
                  size="sm"
                  colorScheme="red"
                  onClick={() => deleteEvent(event)}
                  marginRight="2"
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <ReactPaginate
        pageCount={Math.ceil(totalEvents / itemsPerPage)}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        onPageChange={handlePageChange}
        containerClassName="pagination"
        activeClassName="active"
      />

      {/* Add/Edit Event Modal */}
      <Modal
        size={"2xl"}
        isOpen={isAddingEvent || isEditingEvent}
        onClose={closeModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {isEditingEvent ? "แก้ไข Event" : "เพิ่ม Event"}
          </ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel>ชื่องาน<Text color="red" display="inline">*</Text></FormLabel>
              <Input
                type="text"
                value={editedEvent.name}
                onChange={(e) =>
                  setEditedEvent({ ...editedEvent, name: e.target.value })
                }
              />
            </FormControl>
            <FormControl mt={3}>
              <FormLabel>ความยาว code ของ wristband<Text color="red" display="inline">*</Text></FormLabel>
              <Input
                type="number"
                value={editedEvent.codeLength}
                onChange={(e) =>
                  setEditedEvent({ ...editedEvent, codeLength: e.target.value })
                }
              />
            </FormControl>
            <FormControl mt={3}>
              <FormLabel>วันเริ่มงาน<Text color="red" display="inline">*</Text></FormLabel>
              <Input
                type="date"
                value={editedEvent.startDate}
                onChange={(e) =>
                  setEditedEvent({ ...editedEvent, startDate: e.target.value })
                }
              />
            </FormControl>
            <FormControl mt={3}>
              <FormLabel>วันที่งานสิ้นสุด<Text color="red" display="inline">*</Text></FormLabel>
              <Input
                type="date"
                value={editedEvent.endDate}
                onChange={(e) =>
                  setEditedEvent({ ...editedEvent, endDate: e.target.value })
                }
              />
            </FormControl>
            <FormControl mt={3}>
              <FormLabel>สถานะ<Text color="red" display="inline">*</Text></FormLabel>
              <Select
                value={editedEvent.status}
                onChange={(e) =>
                  setEditedEvent({ ...editedEvent, status: e.target.value })
                }
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Select>
            </FormControl>
            <FormControl mt={3}>
              <FormLabel>รายละเอียด</FormLabel>
              <Textarea
                value={editedEvent.description}
                onChange={(e) =>
                  setEditedEvent({
                    ...editedEvent,
                    description: e.target.value,
                  })
                }
              />
            </FormControl>

            <FormControl mt={3}>
              <FormLabel>สถานที่</FormLabel>
              <Input
                type="text"
                value={editedEvent.venue}
                onChange={(e) =>
                  setEditedEvent({ ...editedEvent, venue: e.target.value })
                }
              />
            </FormControl>

            <FormControl mt={3}>
              <FormLabel>ที่อยู่</FormLabel>
              <Textarea
                value={editedEvent.address}
                onChange={(e) =>
                  setEditedEvent({ ...editedEvent, address: e.target.value })
                }
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button mr={2} colorScheme="teal" onClick={saveEvent}>
              บันทึก
            </Button>
            <Button onClick={closeModal}>ยกเลิก</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
    </>
  );
};

export default EventsList;
