// HorizontalChart.js
import React from 'react';
import Chart from 'react-apexcharts';
import { Box } from '@chakra-ui/react';

const HorizontalChart = ({ data, onDataPointSelection, width }) => {
  const options = {
    chart: {
      type: 'bar',
      horizontalAlign: 'left',
      events: {
        dataPointSelection: (event, chartContext, config) => {
          const selectedDataPointIndex = config.dataPointIndex;
          const selectedChartData = data[selectedDataPointIndex];
          onDataPointSelection(selectedChartData);
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "30px"
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '12px',
        colors: ['#333'],
      },
    },
    yaxis: {
        labels: {
            maxWidth: 450
        },
    },
    xaxis: {
      categories: data.map(item => item.label),
    },
  };

  const series = [
    {
      name: "จำนวนครั้งการสแกน",
      data: data.map(item => item.value),
    },
  ];

  return (
    <Box>
      <Chart options={options} series={series} type="bar" height={140 + 30 * data.length} width={width} />
    </Box>
  );
};

export default HorizontalChart;
