import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import { Box, Badge, Heading, Table, Tbody, Tr, Td, Flex, Text, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { getEventByID } from '../util/api';
import { checkAllParamExist } from '../util/datatool';
import CheckpointsList from './CheckpointList';
import ProductList from './ProductList';

const EventInfo = ({ }) => {
  const [eventData, setEventData] = useState()
  const [searchParams] = useSearchParams();
  const [eventId, setEventId] = useState()

  useEffect(() => {
    async function loadEvent() {
      if (checkAllParamExist(searchParams, ["eid"])) {
        const eid = searchParams.get("eid");
        const result = await getEventByID(eid);
        setEventId(eid)
        
       // setLoading(false);

        if (result && result.data && result.data.event) {
          setEventData(result.data.event);
        }
      }
    }
    loadEvent();
  }, []);

  if (!eventData) {
    return null; // Return early if no eventData is provided
  }

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat('th-TH', options).format(new Date(dateString));
  };

  const BreadCrumb = <Breadcrumb>
  <BreadcrumbItem>
    <BreadcrumbLink color="blue.600" href='/home'>รายการ event ทั้งหมด</BreadcrumbLink>
  </BreadcrumbItem>
  {eventData && <BreadcrumbItem>
    <Text>{eventData.event_name}</Text>
  </BreadcrumbItem>}
</Breadcrumb>

  return (
    <>
    <Flex pt={1} pb={1} pl={4} bgColor="gray.200">{BreadCrumb}</Flex>
    <Box mt={4} p={4} borderWidth="1px" borderRadius="lg" bg="white" boxShadow="lg">
      <Heading mb={2} size="md">
        {eventData.event_name}
      </Heading>
      <Table variant="unstyled">
        <Tbody>
          <Tr borderTop="1px solid" borderBottom="1px solid" borderColor="gray.300">
            <Td bg={"gray.200"} w="100px" maxW="100px">
              <Text fontWeight={600}>สถานะ</Text>
            </Td>
            <Td>
              <Badge colorScheme={eventData.status === 'active' ? 'green' : 'red'}>
                {eventData.status}
              </Badge>
            </Td>
          </Tr>
          <Tr borderBottom="1px solid" borderColor="gray.300">
            <Td bg={"gray.200"} w="150px" maxW="150px">
              <Text fontWeight={600}>วันที่เริ่มงาน</Text>
            </Td>
            <Td>{formatDate(eventData.start_date)}</Td>
          </Tr>
          <Tr borderBottom="1px solid" borderColor="gray.300">
            <Td bg={"gray.200"} w="150px" maxW="150px">
              <Text fontWeight={600}>วันที่งานสิ้นสุด</Text>
            </Td>
            <Td>{formatDate(eventData.end_date)}</Td>
          </Tr>
          <Tr borderBottom="1px solid" borderColor="gray.300">
            <Td bg={"gray.200"} w="150px" maxW="150px">
              <Text fontWeight={600}>รายละเอียด</Text>
            </Td>
            <Td>{eventData.description}</Td>
          </Tr>
          <Tr borderBottom="1px solid" borderColor="gray.300">
            <Td bg={"gray.200"} w="150px" maxW="150px">
              <Text fontWeight={600}>ความยาว code ของ wristband</Text>
            </Td>
            <Td>{eventData.code_length}</Td>
          </Tr>
          <Tr borderBottom="1px solid" borderColor="gray.300">
            <Td bg={"gray.200"} w="150px" maxW="150px">
              <Text fontWeight={600}>สถานที่จัดงาน</Text>
            </Td>
            <Td>{eventData.venue}</Td>
          </Tr>
          <Tr borderBottom="1px solid" borderColor="gray.300">
            <Td bg={"gray.200"} w="150px" maxW="150px">
              <Text fontWeight={600}>ที่อยู่</Text>
            </Td>
            <Td>{eventData.address}</Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>

    {eventId && <CheckpointsList eventId={eventId} eventName={eventData.event_name}  />}
    {eventId && <ProductList eventId={eventId} />}

    </>
  );
};

export default EventInfo;
