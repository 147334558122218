// CheckpointsList.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  IconButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon, QuestionIcon, ViewIcon } from "@chakra-ui/icons";
import { useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import { server } from "../util/connect";
import {
  getCheckpointsFromEventID,
  insertNewCheckpoint,
  updateCheckpoint,
} from "../util/api";
import { setGlobalState } from "../share/share_states";
import { useNavigate } from "react-router-dom";

const CheckpointsList = ({ eventId, eventName }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [searchParams] = useSearchParams();
  const [checkpoints, setCheckpoints] = useState([]);
  const [isAddingCheckpoint, setIsAddingCheckpoint] = useState(false);
  const [isEditingCheckpoint, setIsEditingCheckpoint] = useState(false);
  const [editedCheckpoint, setEditedCheckpoint] = useState({
    id: null,
    name: "",
    description: "",
    score: 0,
    lat: "",
    long: "",
    startTime: "",
    endTime: "",
    status: "inactive",
    orderNum: 0,
  });

  useEffect(() => {
    async function loadCheckpoint() {
      fetchData();
    }
    loadCheckpoint();
  }, []);

  function formatTime(timeString) {
    return moment(timeString, "HH:mm:ss").format("HH:mm");
  }

  const addCheckpoint = () => {
    setIsAddingCheckpoint(true);
  };

  const editCheckpoint = (checkpoint) => {
    setEditedCheckpoint({
      id: checkpoint.id,
      name: checkpoint.checkpoint_name,
      description: checkpoint.description,
      score: checkpoint.score,
      lat: checkpoint.lat,
      long: checkpoint.long,
      startTime: formatTime(checkpoint.start_time),
      endTime: formatTime(checkpoint.end_time),
      status: checkpoint.status,
      orderNum: checkpoint.order_num,
    });
    setIsEditingCheckpoint(true);
  };

  const closeModal = () => {
    setIsAddingCheckpoint(false);
    setIsEditingCheckpoint(false);
    setEditedCheckpoint({
      id: null,
      name: "",
      description: "",
      score: 0,
      lat: "",
      long: "",
      startTime: "",
      endTime: "",
      status: "inactive",
      orderNum: 0,
    });
  };

  const getStatusColor = (status) => {
    return (
      <Text color={status === "active" ? "green" : "orange.500"}>{status}</Text>
    );
  };

  const isPositiveInteger = (value) => {
    return /^\d+$/.test(value) && parseInt(value, 10) >= 0;
  };

  const saveCheckpoint = async () => {
    // Validation: Check if all required fields are filled
    if (
      !editedCheckpoint.name ||
      !editedCheckpoint.startTime ||
      !editedCheckpoint.endTime ||
      !isPositiveInteger(editedCheckpoint.score) ||
      !editedCheckpoint.status
    ) {
      toast({
        title: "Validation Error!",
        description: "Please fill in all fields.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    try {
      const formattedStartTime = moment(
        editedCheckpoint.startTime,
        "HH:mm"
      ).format("HH:mm:ss");
      const formattedEndTime = moment(editedCheckpoint.endTime, "HH:mm").format(
        "HH:mm:ss"
      );
      setGlobalState("loadingtext", "saving, please wait...");
      setGlobalState("loading", true);

      if (editedCheckpoint.id) {
        await updateCheckpoint(
          editedCheckpoint.id,
          editedCheckpoint.name,
          editedCheckpoint.description,
          editedCheckpoint.score,
          editedCheckpoint.lat,
          editedCheckpoint.long,
          formattedStartTime,
          formattedEndTime,
          editedCheckpoint.status
        );
        setGlobalState("loading", false);

        Swal.fire({
          icon: "success",
          title: "Checkpoint Updated",
          text: "Your checkpoint has been updated successfully.",
        });
      } else {
        // Insert new checkpoint
        await insertNewCheckpoint(
          editedCheckpoint.name,
          editedCheckpoint.description,
          editedCheckpoint.score,
          editedCheckpoint.lat,
          editedCheckpoint.long,
          formattedStartTime,
          formattedEndTime,
          editedCheckpoint.status,
          eventId
        );
        setGlobalState("loading", false);

        Swal.fire({
          icon: "success",
          title: "Checkpoint Added",
          text: "Your checkpoint has been added successfully.",
        });
      }

      fetchData();
      closeModal();
    } catch (error) {
      setGlobalState("loading", false);
      closeModal();
      console.error("Error saving checkpoint:", error);

      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to save the checkpoint. Please try again later.",
      });
    }
  };

  const deleteCheckpoint = (checkpoint) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this checkpoint!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios.post(server + `/checkpoint/delete_checkpoint`, {
          id: checkpoint.id,
        });
        fetchData();

        toast({
          title: "Deleted!",
          description: "Your checkpoint has been deleted.",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
    });
  };

  const fetchData = async () => {
    try {
      setGlobalState("loadingtext", "loading, please wait...");
      setGlobalState("loading", true);
      const response = await getCheckpointsFromEventID(eventId);
      setCheckpoints(response.data.checkpoint);
      setGlobalState("loading", false);
      //console.log(response.data.checkpoint)
    } catch (error) {
      setGlobalState("loading", false);
      console.error("Error fetching checkpoints:", error);
      Swal.fire(`Error!`, error, "error");
    }
  };

  const renderRequiredStar = () => (
    <Text as="span" color="red.500">
      *
    </Text>
  );

  return (
    <Box p="4">
      <Button colorScheme="teal" onClick={addCheckpoint}>
        Add Checkpoint
      </Button>
      <Table variant="striped" size="md">
        <Thead>
          <Tr>
            <Th>Checkpoint Name</Th>
            <Th>Description</Th>
            <Th>Score</Th>
            <Th>Lat.</Th>
            <Th>Long.</Th>
            <Th>Start Time</Th>
            <Th>End Time</Th>
            <Th>Questions</Th>
            <Th>Status</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {checkpoints.map((checkpoint, index) => (
            <Tr key={index}>
              <Td>{checkpoint.checkpoint_name}</Td>
              <Td>{checkpoint.description}</Td>
              <Td>{checkpoint.score}</Td>
              <Td>{checkpoint.lat}</Td>
              <Td>{checkpoint.long}</Td>
              <Td>{formatTime(checkpoint.start_time)}</Td>
              <Td>{formatTime(checkpoint.end_time)}</Td>
              <Td>{checkpoint.question_count}</Td>
              <Td>{getStatusColor(checkpoint.status)}</Td>
              <Td>
                <Tooltip
                  label={`Edit ${checkpoint.checkpoint_name}`}
                  placement="auto"
                >
                  <IconButton
                    icon={<EditIcon />}
                    size="sm"
                    colorScheme="teal"
                    marginRight="2"
                    onClick={() => editCheckpoint(checkpoint)}
                  />
                </Tooltip>

                <Tooltip
                  label={`Delete ${checkpoint.checkpoint_name}`}
                  placement="auto"
                >
                  <IconButton
                    icon={<DeleteIcon />}
                    size="sm"
                    colorScheme="red"
                    onClick={() => deleteCheckpoint(checkpoint)}
                    marginRight="2"
                  />
                </Tooltip>

                <Tooltip
                  label={`Go to questions for ${checkpoint.checkpoint_name}`}
                  placement="auto"
                >
                  <IconButton
                    icon={<QuestionIcon />}
                    size="sm"
                    colorScheme="blue"
                    onClick={() => {
                      navigate(
                        `/question?cid=${checkpoint.id}&cname=${checkpoint.checkpoint_name}&eid=${eventId}&ename=${eventName}`
                      );
                    }}
                    marginRight="2"
                  />
                </Tooltip>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {/* Add/Edit Checkpoint Modal */}
      <Modal
        size={"2xl"}
        isOpen={isAddingCheckpoint || isEditingCheckpoint}
        onClose={closeModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {isEditingCheckpoint ? "Edit Checkpoint" : "Add Checkpoint"}
          </ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel>Checkpoint Name {renderRequiredStar()}</FormLabel>
              <Input
                type="text"
                value={editedCheckpoint.name}
                onChange={(e) =>
                  setEditedCheckpoint({
                    ...editedCheckpoint,
                    name: e.target.value,
                  })
                }
                required
              />
            </FormControl>
            <FormControl mt={3}>
              <FormLabel>Description</FormLabel>
              <Textarea
                value={editedCheckpoint.description ?? ""}
                onChange={(e) =>
                  setEditedCheckpoint({
                    ...editedCheckpoint,
                    description: e.target.value,
                  })
                }
              />
            </FormControl>
            <FormControl mt={3}>
              <FormLabel>Score {renderRequiredStar()}</FormLabel>
              <Input
                type="number"
                value={editedCheckpoint.score}
                onChange={(e) =>
                  setEditedCheckpoint({
                    ...editedCheckpoint,
                    score: e.target.value,
                  })
                }
              />
            </FormControl>
            <FormControl mt={3}>
              <FormLabel>Latitude</FormLabel>
              <Input
                type="number"
                value={editedCheckpoint.lat}
                onChange={(e) =>
                  setEditedCheckpoint({
                    ...editedCheckpoint,
                    lat: e.target.value,
                  })
                }
              />
            </FormControl>
            <FormControl mt={3}>
              <FormLabel>Longitude</FormLabel>
              <Input
                type="number"
                value={editedCheckpoint.long}
                onChange={(e) =>
                  setEditedCheckpoint({
                    ...editedCheckpoint,
                    long: e.target.value,
                  })
                }
              />
            </FormControl>
            <FormControl mt={3}>
              <FormLabel>Start Time {renderRequiredStar()}</FormLabel>
              <Input
                type="time"
                value={editedCheckpoint.startTime}
                onChange={(e) =>
                  setEditedCheckpoint({
                    ...editedCheckpoint,
                    startTime: e.target.value,
                  })
                }
              />
            </FormControl>
            <FormControl mt={3}>
              <FormLabel>End Time {renderRequiredStar()}</FormLabel>
              <Input
                type="time"
                value={editedCheckpoint.endTime}
                onChange={(e) =>
                  setEditedCheckpoint({
                    ...editedCheckpoint,
                    endTime: e.target.value,
                  })
                }
              />
            </FormControl>
            <FormControl mt={3}>
              <FormLabel>Status {renderRequiredStar()}</FormLabel>
              <Select
                value={editedCheckpoint.status}
                onChange={(e) =>
                  setEditedCheckpoint({
                    ...editedCheckpoint,
                    status: e.target.value,
                  })
                }
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Select>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button mr={2} colorScheme="teal" onClick={saveCheckpoint}>
              Save
            </Button>
            <Button onClick={closeModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CheckpointsList;
