import { Center, Text } from "@chakra-ui/react"

const Setting = ({ }) => {
    return <>
        <Center mt={150}>
            <Text fontSize={20}>To be implemented!</Text>
        </Center>
    </>
}

export default Setting;