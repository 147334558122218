import { Button, Box, Heading, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel, Input, Stack, useDisclosure, Flex, Image } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import axios from 'axios';
import { useAuth } from '../auth/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { server } from '../util/connect';
import Swal from 'sweetalert2';

function Login({hideTool}) {
  const navigate = useNavigate()
  const usernameRef = useRef();
  const passwordRef = useRef();
  const { user, login, logout } = useAuth();

  useEffect(()=>{
    checkExistingUser()
  },[])
  
  const checkExistingUser = () => {
    const access_token = localStorage.getItem('access_token');
    const refresh_token = localStorage.getItem('refresh_token');
    fetchWelcomeData(access_token)
  }

  const fetchWelcomeData = async (accessToken) => {
    try {
      const welcomeEndpoint = '/welcome';
  
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
  
      const response = await axios.get(`${server}${welcomeEndpoint}`, { headers });
      const welcomeData = response.data;
      console.log('Welcome Data:', welcomeData);
      navigate("/home")
    } catch (error) {
      console.error('Request to /welcome failed:', error);
    }
  };

  const userLogin = async (loginuser, password) => {
    try {
      const loginEndpoint = '/auth/login';
  
      const userLoginData = {
        name: loginuser,
        password
      };
  
      const response = await axios.post(`${server}${loginEndpoint}`, userLoginData);
  
      const { access_token, refresh_token } = response.data;
      login(userLoginData)

      //fetchWelcomeData(access_token)
      console.log('Access Token:', access_token);
      console.log('Refresh Token:', refresh_token);

      localStorage.setItem('access_token', access_token);
      localStorage.setItem('refresh_token', refresh_token);

      hideTool(false)
      navigate("/home")

    } catch (error) {
      Swal.fire(`Cannot login!`, "Please check your username and password!", "warning")
      console.error('Login failed:', error);
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    const username = usernameRef.current.value;
    const password = passwordRef.current.value;

    console.log('Username:', username);
    console.log('Password:', password);

    userLogin(username, password)
  };

  return (
    <Flex
      direction={"column"}
      justify="center"
      align="center"
      h="100vh"
      bg="gray.100" // Background color
    >
      <Box mb={2}>
          <Image w="200px" src="/images/txid_logo.png" />
      </Box>
      <Box
        p={6}
        rounded="md"
        bg="white"
        boxShadow="md"
        w="400px" // Set the width of the form container
      >
        <Heading align={"center"} as="h2" size="lg" mb={6}>
          Login
        </Heading>
        <form onSubmit={handleLogin}>
          <FormControl id="username" isRequired>
            <FormLabel>Username</FormLabel>
            <Input type="text" ref={usernameRef} />
          </FormControl>
          <FormControl id="password" isRequired mt={4}>
            <FormLabel>Password</FormLabel>
            <Input type="password" ref={passwordRef} />
          </FormControl>
          <Flex justify="center" mt={6}>
            <Button
              colorScheme="blue"
              type="submit"
              w="100%" // Set button width to 100%
            >
              Login
            </Button>
          </Flex>
        </form>
      </Box>
    </Flex>
  );
}

export default Login;
