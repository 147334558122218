import {
  Box,
  Button,
  Center,
  Flex,
  Select,
  Stack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
} from "@chakra-ui/react";
import HorizontalChart from "../components/charts/HorizontalChart";
import { useEffect, useState } from "react";
import { setGlobalState } from "../share/share_states";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import axios from "axios";
import { server } from "../util/connect";
import Swal from "sweetalert2";
import TitleNumber from "../components/charts/chart_widgets/TitleNumber";
import moment from "moment";

const StyledDatePicker = styled(DatePicker)`
  && {
    border-radius: 5px;
    padding: 7px;
    border: 1px solid #f8f8f8;
  }
`;

const Dashboard = ({}) => {
  /*const chartData = [
    { checkpoint_type: "Category 1", name: "cat1", value: 50 },
    { checkpoint_type: "Category 2", name: "cat2", value: 75 },
    { checkpoint_type: "Category 3", name: "cat3", value: 60 },
  ];*/

  const tableData = [
    ["Row 1, Cell 1", "Row 1, Cell 2", "Row 1, Cell 3"],
    ["Row 2, Cell 1", "Row 2, Cell 2", "Row 2, Cell 3"],
    ["Row 3, Cell 1", "Row 3, Cell 2", "Row 3, Cell 3"],
  ];

  const [selectedEventID, setSelectedEventID] = useState("");
  const [selectedEventName, setSelectedEventName] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [events, setEvents] = useState([]);
  const [scannedCheckpointTable, setScannedCheckpointTable] = useState([]);
  const [checkpointInfoTable, setCheckpointInfoTable] = useState([]);

  const [fetchedData, setFetchedData] = useState(false);
  const [registeredUserNum, setRegisteredUserNum] = useState(undefined);
  const [redeemNum, setRedeemNum] = useState(undefined);
  const [checkinNum, setCheckinNum] = useState(undefined);
  const [pointSumCount, setPointSumCount] = useState(undefined);
  const [questionnaireSumCount, setQuestionnaireSumCount] = useState(undefined);

  const [chartData, setChartData] = useState([]);
  const [redeemChartData, setRedeemChartData] = useState([]);

  const handleDataPointSelection = (selectedChartData) => {
    console.log("Selected Chart Data:", selectedChartData);
    // Do something with the selected chart data
  };

  const getDate = () => {
    if (startDate && !endDate) {
      return moment(startDate).format("YYYY-MM-DD");
    } else if (startDate && endDate) {
      const eDate = moment(endDate).format("DD/MM/YYYY");
      const sDate = moment(startDate).format("DD/MM/YYYY");
      return sDate + " - " + eDate;
    }
  };

  const handleEventChange = (event) => {
    const selection = event.target.value;
    const selectedName = event.target.options[event.target.selectedIndex].text;
    setSelectedEventName(selectedName);
    setSelectedEventID(selection);
  };

  const fetchAllEvent = async () => {
    try {
      setGlobalState("loadingtext", "Loading, please wait...");
      setGlobalState("loading", true);

      const response = await axios.post(server + `/event/list_all_events`, {});
      setEvents(response.data.list);
      setGlobalState("loading", false);
    } catch (error) {
      setGlobalState("loading", false);
      console.error("Error fetching events:", error);
    }
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const fetchUniqueUsers = async () => {
    const response = await axios.post(
      server + `/dashboard/number_of_unique_users`,
      {
        event_id: selectedEventID,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
      }
    );
    return response.data.size;
  };

  const fetchUniqueRedeems = async () => {
    const response = await axios.post(
      server + `/dashboard/number_of_unique_redeems`,
      {
        event_id: selectedEventID,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
      }
    );
    return response.data.size;
  };

  const fetchNumberOfAllCheckins = async () => {
    const response = await axios.post(
      server + `/dashboard/number_of_all_checkins`,
      {
        event_id: selectedEventID,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
      }
    );
    return response.data.size;
  };

  const fetchPointSummationCount = async () => {
    const response = await axios.post(
      server + `/dashboard/point_summation_count`,
      {
        event_id: selectedEventID,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
      }
    );
    const counts = response.data.counts;
    const dataArr = counts.map((r) => ({ label: r.value, value: r.count }));

    setPointSumCount(dataArr);
  };


  const questionnaireSummationCount = async () => {
    const response = await axios.post(
      server + `/dashboard/questionnaire_summation_count`,
      {
        event_id: selectedEventID,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
      }
    );
    const questionnaireArr = response.data.result;
    setQuestionnaireSumCount(questionnaireArr);
  };

  const fetchNumberOfProductRedeems = async () => {
    try {
      const response = await axios.post(
        `${server}/dashboard/number_of_product_redeems`,
        {
          event_id: selectedEventID,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
        }
      );

      const redeems = response.data.redeems;
      const redeemChartData = redeems.map((r) => ({
        label: r.product_name,
        value: r.doc_count,
      }));

      setRedeemChartData(redeemChartData);
      console.log(redeems);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchNumberOfCheckinsByCheckpoint = async () => {
    try {
      const response = await axios.post(
        `${server}/dashboard/number_of_checkins_by_checkpoint`,
        {
          event_id: selectedEventID,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
        }
      );

      const checkpoints = response.data.checkpoints;

      const cps = checkpoints.map((c) => ({
        label: c.checkpoint_name,
        checkpoint_score: c.checkpoint_score,
        checkpoint_type: c.checkpoint_type,
        name: c.key,
        value: c.doc_count,
      }));

      const cps2 = checkpoints.map((c) => ([
        c.checkpoint_name,
        (c.checkpoint_type == "score")?"ได้รับคะแนน":"ตอบแบบสอบถาม",
        c.checkpoint_score
      ]));
      setCheckpointInfoTable(cps2)
      console.log(cps)

      const point = { count: 0, checkin: 0 };
      const question = { count: 0, checkin: 0 };

      checkpoints.forEach((c) => {
        if (c.checkpoint_type === "score") {
          point.count++;
          point.checkin += c.doc_count;
        } else if (c.checkpoint_type === "questionnaire") {
          question.count++;
          question.checkin += c.doc_count;
        }
      });

      setScannedCheckpointTable([
        ["ได้รับคะแนน", point.count, point.checkin],
        ["ตอบแบบสอบถาม", question.count, question.checkin],
      ]);

      setChartData(cps);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    if (!selectedEventID) {
      Swal.fire(`Warning!`, "กรุณาเลือก Event", "warning");
    } else if (!startDate) {
      Swal.fire(`Warning!`, "กรุณาเลือกวันที่", "warning");
    } else {
      try {
        setGlobalState("loadingtext", "Loading, please wait...");
        setGlobalState("loading", true);

        setRegisteredUserNum(await fetchUniqueUsers());
        setRedeemNum(await fetchUniqueRedeems());
        setCheckinNum(await fetchNumberOfAllCheckins());

        await fetchNumberOfProductRedeems();
        await fetchNumberOfCheckinsByCheckpoint();
        await fetchPointSummationCount();
        await questionnaireSummationCount();

        setFetchedData(true);
        setGlobalState("loading", false);
      } catch (error) {
        setGlobalState("loading", false);
        console.error("Error fetching events:", error);
      }
    }
  };

  useEffect(() => {
    fetchAllEvent();
  }, []);

  return (
    <>
      <Stack spacing={4} p={4}>
        <Flex
          width="100%"
          direction={{ base: "column", lg: "column", xl: "row" }}
          align={"center"}
          justify={"center"}
        >
          <Flex direction={{ base: "column", md: "row" }}>
            <Flex align={"center"} p={{ base: 5, xl: 0 }}>
              <Text align={"right"} w="70px" fontWeight={800} mr={2}>
                Event:
              </Text>
              <Select
                placeholder="เลือก Event"
                value={selectedEventID}
                onChange={handleEventChange}
              >
                {events &&
                  events.map((e) => {
                    return (
                      <option
                        onChange={handleEventChange}
                        key={`key${e.id}`}
                        value={`${e.id}`}
                      >
                        {e.event_name}
                      </option>
                    );
                  })}
              </Select>
            </Flex>
          </Flex>

          <Flex direction={{ base: "column", md: "row" }}>
            <Flex align={"center"} p={{ base: 5, xl: 0 }}>
              <Text align={"right"} w="70px" fontWeight={800} mr={2}>
                วันที่:
              </Text>
              <Box mr={2} border="1px" borderColor="gray.200">
                <StyledDatePicker
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date_picker border"
                  selected={startDate}
                  onChange={handleStartDateChange}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                />
              </Box>
            </Flex>
          </Flex>

          <Flex direction={{ base: "column", md: "row" }}>
            <Flex align={"center"} p={{ base: 5, xl: 0 }}>
              <Text align={"right"} w="70px" fontWeight={800} mr={2}>
                ถึงวันที่:
              </Text>
              <Box mr={2} border="1px" borderColor="gray.200">
                <StyledDatePicker
                  wrapperClassName="date_picker border"
                  dateFormat="dd-MM-yyyy"
                  selected={endDate}
                  onChange={handleEndDateChange}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                />
              </Box>
            </Flex>
                </Flex>

          <Flex>
            <Button
              size={"md"}
              mr={2}
              onClick={async () => {
                fetchData();
              }}
            >
              ดึงข้อมูล
            </Button>
          </Flex>
        </Flex>

        {fetchedData && (
          <Flex mt={2} width="100%" justify={"center"}>
            <Flex
              direction={"column"}
              align={"center"}
              justify={"center"}
              width={"1200px"}
              p={2}
            >
              <Text fontSize={20} mb={2} fontWeight={"bold"}>
                {selectedEventName}
              </Text>
              <Text mb={4}>{getDate()}</Text>
              <Flex width={"100%"} justify={"center"}>
                <TitleNumber
                  mr={2}
                  title="จำนวนผู้ลงทะเบียน"
                  value={registeredUserNum}
                />
                <TitleNumber
                  mr={2}
                  title="จำนวนผู้แลกของรางวัล"
                  value={redeemNum}
                />
                <TitleNumber title="จำนวนครั้งการสแกน" value={checkinNum} />
              </Flex>

              <Flex fontSize={20} mt={10} fontWeight={700}>
                จำนวนการสแกนแต่ละจุด
              </Flex>
              <HorizontalChart
                width={"1000px"}
                data={chartData}
                onDataPointSelection={handleDataPointSelection}
              />

              <Flex width={"100%"} p={2} bgColor="gray.100">
                <Flex
                  mr={1}
                  flex={1}
                  align={"center"}
                  direction={"column"}
                  bgColor="white"
                >
                  <Text p={2} fontWeight={700}>ประเภทจุดเช็คอินและการสแกน</Text>
                  <Table colorScheme="gray">
                    <Thead>
                      <Tr>
                        <Th>ประเภทจุดเช็คพ้อยท์</Th>
                        <Th>จำนวนประเภทจุดเช็คพ้อยท์</Th>
                        <Th>จำนวนการสแกน</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {scannedCheckpointTable.map((row, rowIndex) => (
                        <Tr key={rowIndex}>
                          {row.map((cell, cellIndex) => (
                            <Td fontSize={14} key={cellIndex}>{cell}</Td>
                          ))}
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Flex>
                <Flex
                  ml={1}
                  flex={1}
                  align={"center"}
                  direction={"column"}
                  bgColor="white"
                >
                  <Text p={2} fontWeight={700}>จำนวนการแลกของรางวัล</Text>
                  <HorizontalChart
                    width={"500px"}
                    data={redeemChartData}
                    onDataPointSelection={handleDataPointSelection}
                  />
                </Flex>
              </Flex>

              <Flex px={2} pb={2} width={"100%"} bgColor="gray.100">
                <Flex
                  mr={1}
                  flex={1}
                  align={"center"}
                  direction={"column"}
                  bgColor="white"
                >
                  <Text p={2} fontWeight={700}>จำนวนคะแนนที่สะสม</Text>
                  <HorizontalChart
                    width={"500px"}
                    data={pointSumCount}
                    onDataPointSelection={handleDataPointSelection}
                  />
                </Flex>

                <Flex
                  ml={1}
                  flex={1}
                  align={"center"}
                  direction={"column"}
                  bgColor="white"
                >
                    <Text p={2} fontWeight={700}>ประเภทจุดเช็คอินและการสแกน</Text>
                  <Table colorScheme="gray">
                    <Thead>
                      <Tr>
                        <Th>ขื่อจุดเช็คพ้อยท์</Th>
                        <Th>ประเภทจุดเช็คพ้อยท์</Th>
                        <Th>คะแนน</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {checkpointInfoTable.map((row, rowIndex) => (
                        <Tr key={rowIndex}>
                          {row.map((cell, cellIndex) => (
                            <Td fontSize={14} py={2} px={4} key={cellIndex}>{cell}</Td>
                          ))}
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Flex>
              </Flex>


              <Flex width={"100%"} p={2} bgColor="gray.100">
                <Flex
                  mr={1}
                  flex={1}
                  align={"center"}
                  direction={"column"}
                  bgColor="white"
                >
                  <Text p={2} fontWeight={700}>การตอบแบบสอบถาม</Text>
                  <Table colorScheme="gray">
                    <Thead>
                      <Tr>
                        <Th>จุดเช็คอิน (จุดตอบแบบสอบถาม)</Th>
                        <Th>จำนวนนวนการตอบแบบสอบถาม</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {questionnaireSumCount.map((row, rowIndex) => (
                        <Tr key={rowIndex}>
                            <Td fontSize={14}>{row.checkpoint_name}</Td>
                            <Td fontSize={14}>{row.doc_count}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Flex>
                {/* another card */}
              </Flex>

            </Flex>
          </Flex>
        )}
      </Stack>
    </>
  );
};

export default Dashboard;
