export const checkAllParamExist = (params, keyArray) =>{
    if(params){
      var isFound = true
      keyArray.forEach(key => {
        if(params.get(key) == undefined || params.get(key) == null){
          isFound = false;
        }
      });
      return isFound;
    }
    return false;
  }