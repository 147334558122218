import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Select, Stack, Text } from "@chakra-ui/react";
import Spreadsheet from "react-spreadsheet";
import * as XLSX from "xlsx";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { server } from "../util/connect";
import { setGlobalState } from "../share/share_states";
import Swal from "sweetalert2";
import axios from "axios";
import moment from "moment";
import { getCheckpointsFromEventID } from "../util/api";
import th from 'date-fns/locale/th';

const ExcelExportButton = ({ data, filename }) => {

  const transformData = (inputData) => {
    const headers = inputData[0].map(header => header.value);
    const transformedData = [];
  
    for (let i = 1; i < inputData.length; i++) {
      const row = inputData[i].reduce((acc, cell, index) => {
        acc[headers[index]] = cell.value;
        return acc;
      }, {});
      transformedData.push(row);
    }
  
    return transformedData;
  }

  /*console.log(JSON.stringify(data))
  var data2 = [
    {"ID": "8", "Product Name": "test 1", "Required Points":200, "Amount":300},
    {"ID": "18", "Product Name": "test 18", "Required Points":208, "Amount":308}
  ];*/

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(transformData(data));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${filename}.xlsx`);
  };

  return (
    <Button size={"sm"} colorScheme="teal" onClick={exportToExcel}>
      Export
    </Button>
  );
};

function formatSystemDate(dateString) {
  // Assuming dateString is in a format recognized by Moment.js, otherwise parse it accordingly
  const dateMoment = moment(dateString);
  try{
    return dateMoment.format("YYYY-MM-DD");
  }
  catch(error){
    return undefined;
  }
}

const generateEmptyData = (rowCount, colCount) => {
  const rows = [];
  for (let i = 0; i < rowCount; i++) {
    const row = [];
    for (let j = 0; j < colCount; j++) {
      row.push({ value: "" });
    }
    rows.push(row);
  }
  return rows;
};

const getCheckpointType = (questionNum) => {
    return questionNum > 0?"ตอบแบบสอบถาม":"ได้รับคะแนน"
}

const fetchCheckpointData = async (selectedEvent) => {
  try {
    setGlobalState("loadingtext", "loading, please wait...");
    setGlobalState("loading", true);
    const response = await getCheckpointsFromEventID(selectedEvent);
    const checkpoints = response.data.checkpoint;
    const checkpointArr = [[{value: "ID"}, {value: "Checkpoint Name"}, {value: "Checkpoint Type"}, {value: "Score"}, {value: "Latitude"}, {value: "Longitude"}]];

    checkpoints.forEach((c) => {
      checkpointArr.push([
        { value: c.id }, 
        { value: c.checkpoint_name}, 
        { value: getCheckpointType(c.question_count) },
        { value: c.score},
        { value: c.lat},
        { value: c.long}]);
    });

    //setCheckpoints(response.data.checkpoint);
    setGlobalState("loading", false);
    return checkpointArr;
  } catch (error) {
    setGlobalState("loading", false);
    console.error("Error fetching checkpoints:", error);
    Swal.fire(`Error!`, error, "error");
    return null;
  }
};

function convertArray(inputArray) {
  return inputArray.map((row) => row.map((cell) => ({ value: cell })));
}

const fetchCheckInDataByUser = async (date, selectedEvent) => {
  try {
    setGlobalState("loadingtext", "Loading, please wait...");
    setGlobalState("loading", true);

    const response = await axios.post(server + `/export/export_checkin_by_user`, {
      selected_date: formatSystemDate(date),
      event_id: selectedEvent
    });
    const data = convertArray(response.data.list);
    
    const timerId = setTimeout(() => {
        setGlobalState("loading", false);
        clearTimeout(timerId);
    }, 2000);

    if(data.length <= 1){
        setGlobalState("loading", false);
        clearTimeout(timerId);
    }

    return data;
  } catch (error) {
    console.log(error);
    setGlobalState("loading", false);
    //Swal.fire(`Error!`, error, "error");
    return null;
  }
};

const fetchRedeemData = async (startDate, endDate, selectedEvent) => {
    try {
      setGlobalState("loadingtext", "Loading, please wait...");
      setGlobalState("loading", true);
  
      const response = await axios.post(server + `/export/list_redeem_reward`, {
        start_date: formatSystemDate(startDate),
        end_date: formatSystemDate(endDate),
        event_id: selectedEvent
      });
      setGlobalState("loading", false);
      const data = response.data.list;
      return data;
    } catch (error) {
      console.log(error);
      setGlobalState("loading", false);
      return null;
    }
  };

  const fetchCheckInDataByRecord = async (startDate, endDate, selectedEvent) => {
    try {
      setGlobalState("loadingtext", "Loading, please wait...");
      setGlobalState("loading", true);
  
      const response = await axios.post(server + `/export/export_checkin_by_record`, {
        start_date: formatSystemDate(startDate),
        end_date: formatSystemDate(endDate),
        event_id: selectedEvent
      });
      const data = response.data.list;

      const timerId = setTimeout(() => {
        setGlobalState("loading", false);
        clearTimeout(timerId);
      }, 2000);

      if(data.length <= 1){
          setGlobalState("loading", false);
          clearTimeout(timerId);
      }

      return data;
    } catch (error) {
      console.log(error);
      setGlobalState("loading", false);
      return null;
    }
  };

  const fetchQuestionnaire = async (startDate, endDate, selectedEvent) => {
    try {
      setGlobalState("loadingtext", "Loading, please wait...");
      setGlobalState("loading", true);
  
      const response = await axios.post(server + `/export/export_questionnaire`, {
        start_date: formatSystemDate(startDate),
        end_date: formatSystemDate(endDate),
        event_id: selectedEvent
      });
      const data = response.data.list;

      const timerId = setTimeout(() => {
        setGlobalState("loading", false);
        clearTimeout(timerId);
      }, 2000);

      if(data.length <= 1){
          setGlobalState("loading", false);
          clearTimeout(timerId);
      }

      return data;
    } catch (error) {
      console.log(error);
      setGlobalState("loading", false);
      return null;
    }
  };

  const fetchAllProduct = async (eventID) => {
    try {
      setGlobalState("loadingtext", "Loading, please wait...");
      setGlobalState("loading", true);
      
      const response = await axios.post(server + `/product/list_all_web_products_by_event_id`, {event_id: eventID});
      const products = response.data.products;
      console.log(products)
    
      const arr = [[{value: "ID"}, {value: "Product Name"}, {value: "Required Points"}, {value: "Amount"}]];

      products.forEach((p) => {
        arr.push([
          { value: p.id }, 
          { value: p.product_name}, 
          { value: p.required_point },
          { value: p.total_number}]);
      });

      setGlobalState("loading", false);
      return arr;
    } catch (error) {
      setGlobalState("loading", false);
      console.error("Error fetching events:", error);
      return null;
    }
  };

const Report = () => {
  registerLocale('th', th)
  const initialRowCount = 30; // Set the initial number of rows
  const initialColCount = 20; // Set the initial number of columns

  const [selectedOption, setSelectedOption] = useState("");
  const [hideEndDate, setHideEndDate] = useState(false);
  const [hideDatePicker, setHideDatePicker] = useState(true);
  const [hideExportButton, setHideExportButton] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [events, setEvents] = useState([]);
  const [fromDateText, setFromDateText] = useState("จากวันที่");

  useEffect(()=>{
    fetchAllEvent()
  },[])

  const handleSelectChange = (checkpoint) => {
    setHideExportButton(true)
    setData(generateEmptyData(initialRowCount, initialColCount));
    const selection = checkpoint.target.value;
    setSelectedOption(selection);
    if (selection == "" || selection == "checkpoint_info" || selection == "reward_info") {
      setHideDatePicker(true);
    } else {
      setHideDatePicker(false);
      if(selection == "check_in_info_by_user"){
        setFromDateText("เลือกวันที่")
        setHideEndDate(true)
      }
      else{
        setFromDateText("จากวันที่")
        setHideEndDate(false)
      }
    }
  };

  const handleEventChange = (event) => {
    setHideExportButton(true)
    setData(generateEmptyData(initialRowCount, initialColCount));
    const selection = event.target.value;
    setSelectedEvent(selection)
  }

  const [data, setData] = useState(
    generateEmptyData(initialRowCount, initialColCount)
  );

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const fetchAllEvent = async () => {
    try {
      setGlobalState("loadingtext", "Loading, please wait...");
      setGlobalState("loading", true);
      
      const response = await axios.post(server + `/event/list_all_events`, {});
      setEvents(response.data.list);
      setGlobalState("loading", false);
    } catch (error) {
      setGlobalState("loading", false);
      console.error("Error fetching events:", error);
    }
  };

  const fetchData = async () => {
    var info = [];
    //alert(selectedOption)
    if (selectedOption != "" && selectedEvent != undefined) {
      if (selectedOption == "check_in_info_by_user") {
        info = await fetchCheckInDataByUser(startDate, selectedEvent);
      }
      else if (selectedOption == "check_in_info") {
        info = await fetchCheckInDataByRecord(startDate, endDate, selectedEvent)
      }
      else if(selectedOption == "checkpoint_info"){
        info = await fetchCheckpointData(selectedEvent)
      }
      else if(selectedOption == "reward_redeem_info"){
        info = await fetchRedeemData(startDate, endDate, selectedEvent)
      }
      else if(selectedOption == "reward_info"){
        info = await fetchAllProduct(selectedEvent)
      }
      else if(selectedOption == "questionnaire"){
        info = await fetchQuestionnaire(startDate, endDate, selectedEvent)
      }

      if (info == null || info.length <= 1) {
        Swal.fire(
          `Warning!`,
          `ไม่พบข้อมูล`,
          "warning"
        );
        setHideExportButton(true)
        setData(generateEmptyData(initialRowCount, initialColCount));
      } else {
        setHideExportButton(false)
        setData(info);
      }
    } else {
      Swal.fire(`Warning!`, "กรุณาเลือกข้อมูลที่ต้องการแสดง", "warning");
    }
  };

  return (
    <Stack spacing={4} p={4}>
      <Flex  width="100%"
          direction={{ base: "column", lg: "column", xl: "row" }}
          align={"center"}
          justify={"center"}>
      <Flex direction={{base: "column", md: "row"}}>
        <Box p={4}>
          <Select
            placeholder="เลือก Event"
            value={selectedEvent}
            onChange={handleEventChange}
          >
            {events && events.map((e)=>{
                return <option onChange={handleEventChange} key={`key${e.id}`} value={`${e.id}`}>
                {e.event_name}
              </option>
            })}
            {/* Add more options as needed */}
          </Select>
        </Box>

        <Box p={4}>
          <Select
            maxW={"400px"}
            placeholder="เลือกประเภทข้อมูล"
            value={selectedOption}
            onChange={handleSelectChange}
          >
            <option value="check_in_info">
              ข้อมูลการเช็คอินของผู้เข้าร่วมกิจกรรม
            </option>
            <option value="check_in_info_by_user">
              ข้อมูลการเช็คอินของผู้เข้าร่วมกิจกรรมแยกตามรหัส
            </option>
            <option value="checkpoint_info">ข้อมูลการจุดเช็คอิน</option>
            <option value="reward_info">ข้อมูลของรางวัล</option>
            <option value="reward_redeem_info">ข้อมูลการแลกของรางวัล</option>
            <option value="questionnaire">แบบสอบถาม</option>
            {/* Add more options as needed */}
          </Select>
        </Box>
        </Flex>

        {!hideDatePicker && (
          <Flex direction={{base: "column", md: "row"}}>
            <Flex p={{base: 5, xl: 0}} >
              <Text align={"right"} w="70px" fontWeight={800} mr={2}>
                {fromDateText}:
              </Text>
              <Box mr={2} border='1px' borderColor='gray.200'>
                <DatePicker
                  locale="th"
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName='date_picker border'
                  selected={startDate}
                  onChange={handleStartDateChange}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                />
              </Box>
            </Flex>
            {!hideEndDate && <Flex p={{base: 5, xl: 0}}>
              <Text align={"right"} w="70px" fontWeight={800} mr={2}>
                ถึงวันที่:
              </Text>
              <Box mr={2} border='1px' borderColor='gray.200'>
                <DatePicker
                  locale="th"
                  dateFormat="dd-MM-yyyy"
                  selected={endDate}
                  onChange={handleEndDateChange}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate} // Optional: Restrict end date to be after the start date
                />
              </Box>
            </Flex>}
            
            
          </Flex>
        )}

        <Flex>
          <Button
            size={"sm"}
            mr={2}
            onClick={async () => {
              fetchData();
            }}
          >
            ดึงข้อมูล
          </Button>

          {!hideExportButton && <ExcelExportButton
            data={data}
            filename={`export_${startDate.toISOString().slice(0, 10)}`}
          />}
        </Flex>
      </Flex>
      
      {data && data.length > 1 && <Text color="gray.400">จำกัดจำนวนไว้ไม่เกิน 20,000 record หากต้องการค้นหาจำนวนมากกว่านี้ให้แบ่งวันในการค้นหา</Text>}
      <Box overflowX="auto" overflowY="auto" maxW="100%" maxH="100vh">
        <Spreadsheet data={data} onChange={setData} />
      </Box>
    </Stack>
  );
};

export default Report;
