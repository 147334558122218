import { createGlobalState } from 'react-hooks-global-state';

const initialState = { 
    loading: false,
    loadingtext: "loading..."
};

const { useGlobalState, setGlobalState } = createGlobalState(initialState);

export { useGlobalState, setGlobalState };
